/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "./src/css/index.css"

import React from 'react'
import { ApolloProvider } from '@apollo/client'
import client from './src/client'

import ChatBox from './src/components/ChatBox'

export const wrapRootElement = ({element}) => (
    <ApolloProvider client={client}>
        {element}
        <ChatBox/>
    </ApolloProvider>
)