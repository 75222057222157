// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-event-index-js": () => import("./../../../src/templates/event/index.js" /* webpackChunkName: "component---src-templates-event-index-js" */),
  "component---src-templates-page-page-buynow-js": () => import("./../../../src/templates/page/page-buynow.js" /* webpackChunkName: "component---src-templates-page-page-buynow-js" */),
  "component---src-templates-page-page-contact-us-js": () => import("./../../../src/templates/page/page-contact-us.js" /* webpackChunkName: "component---src-templates-page-page-contact-us-js" */),
  "component---src-templates-page-page-events-js": () => import("./../../../src/templates/page/page-events.js" /* webpackChunkName: "component---src-templates-page-page-events-js" */),
  "component---src-templates-page-page-home-js": () => import("./../../../src/templates/page/page-home.js" /* webpackChunkName: "component---src-templates-page-page-home-js" */),
  "component---src-templates-page-page-variants-js": () => import("./../../../src/templates/page/page-variants.js" /* webpackChunkName: "component---src-templates-page-page-variants-js" */),
  "component---src-templates-page-page-vixens-js": () => import("./../../../src/templates/page/page-vixens.js" /* webpackChunkName: "component---src-templates-page-page-vixens-js" */)
}

