import React from 'react'
import { LiveChatLoaderProvider, Messenger } from 'react-live-chat-loader'

const facebookPageId = "194579840743432";

const ChatBox = () => {
    return (
        <div role="button" aria-label="chatbox">
            <LiveChatLoaderProvider 
                provider="messenger" 
                providerKey={`${facebookPageId}`} 
                locale="en_PH" >
                <Messenger color="#fe0d32"/>
            </LiveChatLoaderProvider>
        </div>
    )
}

export default ChatBox;